import React, { useContext, useEffect, useState } from 'react';
import { MenuSideBar } from '../../components/MenuSideBar';
import { AuthContext } from '../../Contexts/AuthContext';
import { Header } from '../../components/Header';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import { MdDelete, MdEdit } from 'react-icons/md';
import { DeleteDataModal } from '../../components/admin/DeleteDataModal';
import { EditDataModal } from '../../components/admin/EditDataModal';
import { NavItem } from 'react-bootstrap';

export const DashboardTravels = () => {
    const {auth} = useContext(AuthContext);

    const [rows, setRows] = useState([]);
    const [filterRow, setFilterRow] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [item, setItem] = useState({});

    const URI = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
    const CONFIG = {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${auth}`,
            'accept': 'application/json'
        }
    }

    const FIELDS_CAD_TRAVEL = [
        'id',
        'viajante_id',
        'cia_aerea',
        'num_voo',
        'localizador',
        'dt_partida',
        'hr_partida',
        'aero_partida',
        'dt_chegada',
        'hr_chegada',
        'aero_chegada',
        'cidade_encomenda',
        'local_encomenda',
        'cep_entrega',
        'rua_entrega',
        'num_entrega',
        'bairro_entrega',
        'cidade_entrega',
        'estado_entrega',
        'status',
        'cep_encomenda',
        'num_encomenda',
        'complemento_chegada',
        'complemento_partida'
    ];


    const handleShowDeleteModal = (id)=>{
        console.log(id)
        setItem({...item, id:id});
        setShowDelete(true);
    }

    const handleEditModal = (id)=>{
        setShowEdit(true);
        setItem(rows.filter(r=>r.id === id));
    }

    const handleDeleteData = ()=>{
        axios.delete(`${URI}/viajantes/${item.id}`, CONFIG)
        .then((res)=>{
            const removedId = rows.filter(row=>row.id !== item.id);
            setRows(removedId);
            setShowDelete(false);
        })
        .catch((error)=>console.log(error));
    }

    const handleEditData = (e)=>{
        e.preventDefault();
        
        const FILTERED_FIELD = FIELDS_CAD_TRAVEL.filter(f=>f!=='id'&&f!=='data_cadastro');
        const fd = new FormData();
        FILTERED_FIELD.map(field=>(fd.append(field, e.target[field].value)));

        axios.patch(`${URI}/cadastro_viagens/${item[0].id}`, fd, CONFIG)
        .then(res=>{
            const filter = rows.filter((row)=>row.id !== res.data.id);
            setRows([{filter, ...res.data}]);
            setShowEdit(false);
        })
        .catch(error=>console.log(error));
    }

    useEffect(_=>{
        if(filterRow.length <= 0){
            axios.get(`${URI}/cadastro_viagens`, CONFIG)
            .then(res=>{
                setRows(res.data);
                setFilterRow(FIELDS_CAD_TRAVEL);
            })
            .catch(error=>console.log(error));
        }
    })

    return (
        <div className='d-flex vh-100'>
            <MenuSideBar adminSideBar travels/>
            <div className="flex-grow-1 d-flex flex-column" style={{overflowY: 'auto', padding: '2.5rem 1.5rem 3.5rem 3.5rem', backgroundColor: '#F7FAFA'}}>
                <Header title={'Admin'}/>
                <div className='d-flex flex-column' style={{paddingRight: '11.5rem', gap: '2.375rem', marginTop: '3.5rem'}}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {FIELDS_CAD_TRAVEL.map((header)=>(   
                                        <TableCell className='fw-bold'>{header}</TableCell>
                                    ))}
                                    <TableCell className='fw-bold'>Excluir</TableCell>
                                    <TableCell className='fw-bold'>Editar</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {FIELDS_CAD_TRAVEL.map(header=>(
                                        <TableCell align="center">{row[header] ?? 'NULL'}</TableCell>
                                    ))}
                                    <TableCell align="center"><MdDelete style={{cursor: 'pointer'}} className='text-danger' onClick={_=>handleShowDeleteModal(row.id)}/></TableCell>
                                    <TableCell align="center"><MdEdit style={{cursor: 'pointer'}} className='text-info' onClick={_=>handleEditModal(row.id)}/></TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                </div>
            </div>

            <DeleteDataModal 
                show={showDelete} 
                setShow={setShowDelete} 
                item={item.name} 
                id={item.id}
                handleDeleteData={handleDeleteData}
            />

            <EditDataModal
                handleEditData={handleEditData}
                fields={filterRow}
                item={item[0]}
                show={showEdit}
                setShow={setShowEdit}
            />
        </div>
    );
}
