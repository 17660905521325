import ReactInputMask from "react-input-mask"
import { PersonalContainerStyle } from "./styles/PersonalContainerStyle.styled"
import { useEffect, useState } from "react"
import { MdAccountCircle, MdEdit } from "react-icons/md";
import { Modal } from "react-bootstrap";
import {LoginButton} from "../components/styles/LoginButton.styled";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";

export const PersonalInfoContainer = ({setUser, user})=>{
    const [title, setTitle] = useState('');
    const [file, setFile] = useState({});
    const [show, setShow] = useState(false);
    const [image, setImage] = useState('');

    const MAX_SIZE = 5 * 1024 * 1024;
    const URI = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
    const URI_STORAGE = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_STORAGE_URL_DEV : process.env.REACT_APP_API_STORAGE_URL_PROD;
    const CONFIG = {
        headers:{
            "content-type": 'multipart/form-data',
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    };

    const handleFileChange = (e)=>{
        setFile({});
        const img = e.target.files[0];
        if (img) {
            if(img.size > MAX_SIZE){
                alert(`O arquivo excede o tamanho máximo de ${MAX_SIZE / (1024 * 1024)} mb`)
                return
            }
            const imageUrl = URL.createObjectURL(img);
            setFile({image: img, name:imageUrl});
        }
        setShow(true);
    }

    const handleSaveImage = ()=>{
        const fd = new FormData();

        fd.append('imagem', file.image);
        fd.append('_method', 'PATCH');
        
        let type;
        
        if(user.hasOwnProperty('cpf') && !user.hasOwnProperty('cnh')){
            type = 'viajantes';
        }
        if(user.hasOwnProperty('cnpj')){
            type = 'empresas';
        }

        axios.post(`${URI}/${type}/${user.id}`, fd, CONFIG)
        .then(res=>{
            setUser(res.data);
            setImage(file.name);
            setShow(false);
        })
        .catch(error=>console.log(error));
    }

    useEffect(()=>{
        if(user.hasOwnProperty('cpf') && !user.hasOwnProperty('cnh')){
            setTitle('Informações do viajante');
        } else {
            setTitle('Informações da empresa')
        }
    }, [title, user]);

    return (
        <PersonalContainerStyle>
            <h5>{title}</h5>
            <div className="d-flex flex-column flex-md-row gap-4 personal align-items-center">
                <div className="img-container">

                    {image.length <= 0 ?
                    <div className="img" style={{backgroundImage: `url(${user.imagem?`${URI_STORAGE}/${user.imagem}`:'https://placehold.co/140x140'})`}}>
                    </div>
                    : <div style={{backgroundImage: `url(${image})`}} className="img"/>}
                    
                    <label htmlFor="file" className="btn-file d-flex align-items-center justify-content-center"><MdEdit/></label>
                    <input onChange={handleFileChange} type="file" accept=".jpeg,.jpg,.mpeg,.png" id="file" hidden/>
                </div>

                <div className="d-flex flex-row flex-wrap gap-3">
                    {user.hasOwnProperty('cpf') && !user.hasOwnProperty('cnh') ?
                    <div className="d-flex gap-3 flex-column flex-md-row align-items-center justify-content-md-start flex-grow-1">
                        <div className="w-100">
                            <label className="fw-bold">Nome completo</label>
                            <input readOnly className="form-control mt-2" value={`${user.nome}`}/>
                        </div>

                        <div className="w-100">
                            <label className="fw-bold">CPF</label>
                            <ReactInputMask readOnly mask={"999.999.999-99"} className="form-control mt-2" value={user.cpf}/>
                        </div>
                    </div>
                    : 
                    <div className="d-flex gap-3 flex-column flex-md-row align-items-center justify-content-md-start flex-grow-1">
                        <div className="w-100">
                            <label className="fw-bold">Nome da empresa</label>
                            <input readOnly className="form-control mt-2" value={`${user.nome}`}/>
                        </div>

                        <div className="w-100">
                            <label className="fw-bold">CNPJ</label>
                            <ReactInputMask readOnly mask={"99.999.999/9999-99"} className="form-control mt-2" value={user.cnpj}/>
                        </div>
                    </div>
                    }   

                    <div className="d-flex gap-3 flex-column flex-md-row align-items-center justify-content-md-start flex-grow-1">
                        {user.hasOwnProperty('cnpj') ?
                        <div className="w-100">
                            <label className="fw-bold">Nome fantasia</label>
                            <input readOnly className="form-control mt-2" value={user.nome_fantasia}/>   
                        </div>
                        :''}

                        <div className="w-100">
                            <label className="fw-bold">E-mail</label>
                            <input readOnly className="form-control mt-2" value={user.email}/>   
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={show}
                onHide={_=>setShow(false)}
                size="md"
            >
                <Modal.Header closeButton={true}>
                    <h4 className="mb-0 fw-bold text-muted">Confirmar</h4>
                </Modal.Header>
                <Modal.Body className="text-center p-4">
                    <LazyLoadImage 
                    effect="blur" 
                    className="rounded" 
                    alt='Imagem escolhida pelo usuário' 
                    src={file.name}
                    placeholderSrc={file.name} 
                    width={'100%'}
                    height={300}
                    />

                    <LoginButton className="mt-3 w-100" onClick={handleSaveImage}>Salvar</LoginButton>
                </Modal.Body>
            </Modal>
        </PersonalContainerStyle>
    )
}