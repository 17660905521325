import ReactInputMask from "react-input-mask";
import { StyledAside } from "../../components/styles/Aside.styled";
import { LoginForm } from "../../components/styles/LoginForm.styled";
import { Required } from "../../components/styles/Required.styled";
import { Tooltip } from "../../components/styles/Tooltip.styled";
import { LoginButton } from "../../components/styles/LoginButton.styled";
import { useContext, useState } from "react";
import { AuthContext } from "../../Contexts/AuthContext";
import { BoxAsideContainer } from "../../components/BoxAsideContainer";
import { LogoNavBar } from "../../components/LogoNavBar";
import axios from "axios";
import { FileInput } from "../../components/FileInput";
import RegisterBox from "../../images/register_box.svg";
import SelectInput from "../../components/forms/SelectInput";
import TextInput from "../../components/forms/TextInput";

export const RegisterDelivererVehicle = () => {
  const { user, auth, setUser } = useContext(AuthContext);
  const [crlvName, setCrlvName] = useState("Anexe o CRLV do seu veículo");
  const [cnhName, setCnhName] = useState("Anexe sua CNH");

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${auth}`,
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("veiculo", e.target.vehicle.value);
    fd.append("marca_veiculo", e.target.brand.value);
    fd.append("ano_veiculo", e.target.year.value);
    fd.append("placa_veiculo", e.target.plate.value.replace("-", ""));
    fd.append("crlv_veiculo", e.target.crlv.files[0]);
    fd.append("cnh", e.target.cnh.files[0]);

    Array.from(fd);

    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_PROD
        }/entregadores/${user.id}?_method=PATCH`,
        fd,
        config
      )
      .then((res) => {
        setUser({ ...user, cnh: res.data.cnh });
      })
      .catch((e) => e);
  };

  return (
    <section style={{ minHeight: "100%" }} className="d-flex">
      <StyledAside className="w-100 w-lg-50 d-flex flex-column container">
        <div>
          <LogoNavBar />
        </div>
        <div className="d-flex flex-column flex-grow-1 justify-content-center">
          <div>
            <h2 className="fw-bolder">Vamos continuar sua conta</h2>
            <span className="text-muted">
              Termine seu cadastro para acessar a plataforma
            </span>
          </div>

          <LoginForm
            style={{ alignItems: "normal" }}
            className="mt-5"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="row">
              <div className="col-12">
                <SelectInput
                 name="vehicle"
                 id="vehicle"
                  options={[
                    { value: "moto", texto: "Moto" },
                    { value: "carro", texto: "Carro" },
                  ]}
                  placeholder="Escolha seu tipo de conta"
                  label="Veículo"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <TextInput
                  mask={"9999"}
                  type="year"
                  name="year"
                  id="year"
                  placeholder="Ano do veículo"
                  required
                  label="Ano"
                />
              </div>

              <div className="col-12 col-lg-6">
                <TextInput
                 mask={"AAA-9999"}
                 type="text"
                 name="plate"
                 id="plate"
                 placeholder="Placa do veículo"
                  required
                  label="Placa"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
              <TextInput
                 type="text"
                 name="brand"
                 id="brand"
                 placeholder="Qual a marca do seu veículo?"
                 required
                  label="Marca do veículo"
                />
              </div>
            </div>

            <div className="w-100">
              <FileInput
                label="CRLV"
                id="crlv"
                name="crlv"
                placeholder={crlvName}
                onChange={(e) =>
                  setCrlvName(
                    e.target.files[0] ? e.target.files[0].name : crlvName
                  )
                }
              />
            </div>

            <div className="w-100">
              <FileInput
                label="CNH"
                id="cnh"
                name="cnh"
                placeholder={cnhName}
                onChange={(e) =>
                  setCnhName(
                    e.target.files[0] ? e.target.files[0].name : cnhName
                  )
                }
              />
            </div>

            <div className="d-flex justify-content-between w-100">
              <LoginButton className="w-100" type="submit">
                Acessar
              </LoginButton>
            </div>
          </LoginForm>
        </div>
      </StyledAside>
      <BoxAsideContainer image={RegisterBox} width={712} />
    </section>
  );
};
