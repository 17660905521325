import React, { useContext } from "react";
import {Navigate, Route, Routes} from 'react-router-dom';
import RegisterDelivererCpf from "../pages/deliverer/RegisterDelivererCpf";
import { RegisterDelivererAddress } from "../pages/deliverer/RegisterDelivererAddress";
import { RegisterDelivererVehicle } from "../pages/deliverer/RegisterDelivererVehicle";
import { AuthContext } from "../Contexts/AuthContext";

const ConfigDelivererControl = ()=>{
    const {user, auth, setAuth} = useContext(AuthContext);
    return <RegisterDelivererVehicle/>
    if(!auth || !user) {
        <Navigate to="/login"/>
    } else if (!user.hasOwnProperty('cnh')){
        // return <Navigate to="*"/>;
        return null;
    }

    
    if(!user.cpf){
        return <RegisterDelivererCpf/>
    }
    
    if(!user.cep){
        return <RegisterDelivererAddress/>
    }
    
    if(!user.cnh){
        return <RegisterDelivererVehicle/>
    }
    
    if(!user.email_verificado_em){
        return <Navigate to="/verificar-email"/>
    }
    
    return <p>{user.nome}</p>
}

export default ConfigDelivererControl;