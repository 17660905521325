// import logo from '../logo.svg';

import { useContext, useState } from "react";
import { StyledAside } from "../../components/styles/Aside.styled.js";
import { LoginButton } from "../../components/styles/LoginButton.styled.js";
import { LoginForm } from "../../components/styles/LoginForm.styled.js";
import { Required } from "../../components/styles/Required.styled.js";
import { Tooltip } from "../../components/styles/Tooltip.styled.js";
import ReactInputMask from "react-input-mask";
import { AuthContext } from "../../Contexts/AuthContext.js";
import axios from "axios";
import { BoxAsideContainer } from "../../components/BoxAsideContainer.js";
import { LogoNavBar } from "../../components/LogoNavBar.js";
import { cpf } from "cpf-cnpj-validator";
import TextInput from "../../components/forms/TextInput/index.js";
import RegisterBox from "../../images/register_box.svg";

function RegisterDelivererCpf() {
  const { user, setUser, auth } = useContext(AuthContext);
  const [validCpf, setValidCpf] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth}`,
        "Content-Type": "application/json",
      },
    };

    const fd = new FormData();
    fd.append("cpf", e.target.cpf.value.replace(/[^0-9]/g, ""));
    fd.append("nome", e.target.name.value);
    fd.append("sobrenome", e.target.surname.value);
    fd.append("number", e.target.number.value.replace(/[^0-9]/g, ""));

    axios
      .patch(
        `${
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_PROD
        }/entregadores/${user.id}`,
        fd,
        config
      )
      .then((res) => {
        setUser({ ...user, cpf: res.data.cpf });
      })
      .catch((e) => e);
  };

  const handleCpfChange = (e) => {
    const onlyNumbers = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNumbers.length === 11) {
      if (!cpf.isValid(onlyNumbers) || e.target.value === "") {
        setValidCpf(false);
      } else {
        setValidCpf(true);
      }
    }
  };

  return (
    <section style={{minHeight: '100%'}} className="d-flex">
      <StyledAside className="w-100 w-lg-50 d-flex flex-column container">
        <div>
          <LogoNavBar />
        </div>
        <div className="d-flex flex-column flex-grow-1 justify-content-center">
          <div>
            <h2 className="fw-bolder">Vamos verificar seu CNPJ</h2>
            <span className="text-muted">Insira o CPNJ da sua empresa</span>
          </div>

          <LoginForm  style={{ alignItems: "normal" }} className="mt-5" method="POST" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 col-lg-6">
            <TextInput
               type="text"
               name="name"
               id="name"
               placeholder="Seu nome"
               required
                label="Nome"
              />
              </div>

              <div className="col-12 col-lg-6">
              <TextInput
              type="text"
              name="surname"
              id="surname"
              placeholder="Seu sobrenome"
              required
                label="Sobrenome"
              />
              </div>
            </div>
            <div className="row">
            <div className="col-12">
            <TextInput
                mask={"999.999.999-99"}
                type="cpf"
                name="cpf"
                id="cpf"
                placeholder="Insira seu CPF"
                onChange={handleCpfChange}
                required={true}
                label="CPF"
              />
               <span className={`text-danger ${validCpf ? "d-none" : ""}`}>
                CPF inválido!
              </span>
              </div>
            </div>
            <div className="row">
            <div className="col-12">
            <TextInput
                mask={"(99) 9999-9999"}
                type="text"
                name="number"
                id="number"
                placeholder="Insira seu número"
                required
                label="Seu número de telefone"
              />
              </div>
            </div>

            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                required={true}
              />
              <label
                className="form-check-label"
                htmlFor="flexCheckDefault"
                style={{ fontSize: ".75em" }}
              >
                Confirmo a leitura, concordo com todos os termos e autorizo o
                uso dos dados apresentados.
              </label>
            </div>

            <LoginButton className="w-100" type="submit">
              Próximo
            </LoginButton>
          </LoginForm>
        </div>
      </StyledAside>
      <BoxAsideContainer image={RegisterBox} width={712} />
    </section>
  );
}

export default RegisterDelivererCpf;
